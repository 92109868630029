<div>
  <!-- Drawer -->
  <div
    [ngClass]="{
      'w-64 bg-white': isDrawerExpanded,
      'w-16 bg-white': !isDrawerExpanded,
    }"
    class="fixed inset-y-0 left-0 transform transition-all duration-300 ease-in-out border-r z-50 flex flex-col position-container"
  >
    <div class="flex flex-col h-full pt-5 bg-white">
      <!-- Logo -->
      <div class="flex items-center justify-center px-4 me-auto">
        <img
          class="h-8 w-auto"
          src="./assets/images/logo_icon.svg"
          alt="Jamatu logo"
        />
      </div>

      <!-- Navigation Items -->
      <div class="mt-5 flex-grow flex flex-col">
        <nav class="flex-1 px-2 pb-4 space-y-1 menu-item">
          <span
            *ngFor="let item of navItems"
            (click)="goToLink(item)"
            class="group flex items-center rounded-md py-2 px-2"
            [ngClass]="navItemClasses(item.link, item.openNewTab)"
          >
            <span
              [ngClass]="item.iconClass"
              class="text-base text-grayColor hover:text-primaryFontColor"
            ></span>
            <span *ngIf="isDrawerExpanded" class="ml-3 text-primaryFontColor">
              {{ item.text }}
            </span>
          </span>
        </nav>
      </div>

      <!-- Bottom Navigation -->
      <div>
        <nav class="flex-1 px-2 pb-4 space-y-1">
          <span
            *ngFor="let item of bottomNavItems"
            (click)="setActive(item)"
            class="group flex items-center rounded-md py-2 px-2 menu-item menu-item__bottom"
            [ngClass]="{
              'bg-gray-100': isActive(item),
              'text-gray-900': isActive(item),
            }"
          >
            <span
              *ngIf="!item.isSvg; else svgIcon"
              [ngClass]="item.iconClass"
              class="text-base text-grayColor hover:text-primaryFontColor"
            ></span>
            <ng-template #svgIcon>
              <img
                [src]="getSvgUrl(item.iconClass)"
                alt="icon"
                class="menu-item__svg"
              />
            </ng-template>
            <span *ngIf="isDrawerExpanded" class="ml-3">
              {{ item.text }}
            </span>
          </span>
        </nav>
      </div>
    </div>
    <div class="bg-white">
      <button
        (click)="toggleDrawer()"
        class="mr-4 hover:text-gray-600 flex items-center justify-center hover:transform hover:translate-x-2 transition-transform duration-300 show-icon-only"
      >
        <i
          class=""
          [ngClass]="{
            'fa-chevrons-left': isDrawerExpanded,
            'fa-chevrons-right': !isDrawerExpanded,
          }"
          class="fa-solid text-lg text-black"
        ></i>
      </button>
    </div>
  </div>
</div>
