import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@auth0/auth0-angular';
import BusinessFacade from '../../../../../../../../apps/orderadmin/src/app/modules/business/business.facade';
import { Module } from 'apps/orderadmin/src/app/modules/main/pages/modules/models/module.model';
import { MODULE_STATUS } from '../../../../core/constants';
import { OrderModule } from '../../../../../../../../apps/orderadmin/src/app/modules/main/pages/modules/module.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public isShown = false;
  title!: string;
  private _cachedUser: any;
  @Input() isDrawerExpanded: boolean = false;
  @Output() showDrawer: EventEmitter<any> = new EventEmitter();
  @Output() showHelpSidebar: EventEmitter<any> = new EventEmitter();
  constructor(
    private _translate: TranslateService,
    public _authService: AuthService,
    private _businessFacade: BusinessFacade,
  ) {
    this._authService.isAuthenticated$.subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        this._authService.loginWithRedirect();
      }
    });
    const storedValue = localStorage.getItem('isDrawerExpanded');
    this.isDrawerExpanded = storedValue ? JSON.parse(storedValue) : true;
    this._translate
      .get('global.orderDashboardLabel')
      .subscribe((translation) => {
        this.title = translation;
      });
  }

  public switchLang(lang: string): void {
    this._translate.use(lang);
  }

  public onLogout(): void {
    this._authService.logout({
      logoutParams: { returnTo: document.location.origin },
    });
    sessionStorage.clear();
    this._businessFacade.clearUserAndBusinessId();
    localStorage.removeItem('user'); // TODO: needs to be handled with a state class or so.
    localStorage.removeItem('isDrawerExpanded');
  }

  public get showLogo(): boolean {
    const url = window.location.href;
    return url.includes('orders-dashboard');
  }

  public get showSettingsCog(): boolean {
    const url = window.location.href;
    return url.includes('orders-dashboard');
  }

  private getUserFromLocalStorage(): any {
    if (!this._cachedUser) {
      this._cachedUser = JSON.parse(localStorage.getItem('user') || '{}');
    }
    return this._cachedUser;
  }

  public get companyLogo() {
    const user = this.getUserFromLocalStorage();
    const url = window.location.href;
    if (!url.includes('customers')) {
      if (user?.logoImageUrl) {
        return user?.logoImageUrl;
      } else {
        return false;
      }
    }
    return false;
  }

  public get companyName() {
    return this.getUserFromLocalStorage().name;
  }

  public get isWaiterApp() {
    const url = window.location.href;
    return url.includes('waiterapp');
  }

  public get getSelectedTableFromWaiterApp() {
    let table = JSON.parse(localStorage.getItem('selectedTable') || '{}');
    var noTableText = '';
    this._translate.get('waiterApp.noTableSelected').subscribe((text) => {
      noTableText = text;
    });

    return table?.number && table?.area
      ? `#${table?.number} - ${table?.area}`
      : noTableText;
  }

  public toggleShow(): void {
    this.isShown = !this.isShown;
  }
  toggleSideBarSettings() {
    this.showDrawer.emit();
  }

  toggleHelpSidebar() {
    this.showHelpSidebar.emit();
  }

  isDashboard() {
    const url = window.location.href;
    return url.includes('orders-dashboard');
  }

  public get pausedAtLeastOne(): boolean {
    const modules = this.getModulesStatus();
    return modules.some((module) => module.isPaused);
  }

  public get pausedBoth(): boolean {
    const modules = this.getModulesStatus();
    const deliveryPaused = this.IsPaused(modules, OrderModule.Delivery);
    const takeAwayPaused = this.IsPaused(modules, OrderModule.TakeAway);
    return deliveryPaused && takeAwayPaused;
  }

  public get bannerMessage(): string | null {
    const modules = this.getModulesStatus();
    const deliveryPaused = this.IsPaused(modules, OrderModule.Delivery);
    const takeAwayPaused = this.IsPaused(modules, OrderModule.TakeAway);

    if (deliveryPaused && takeAwayPaused) {
      return this._translate.instant('OrderDashboard.StatusBanner.BothPaused');
    }

    if (deliveryPaused || takeAwayPaused) {
      const module = deliveryPaused
        ? OrderModule.Delivery
        : OrderModule.TakeAway;
      return this._translate.instant(
        'OrderDashboard.StatusBanner.SinglePaused',
        { module },
      );
    }

    return null;
  }

  private getModulesStatus(): Module[] {
    const modules = sessionStorage.getItem(MODULE_STATUS);
    return modules ? JSON.parse(modules) : [];
  }
  IsPaused(modules: Module[], moduleName: string) {
    return modules.some(
      (module) =>
        module.name === moduleName && module.isPaused && module.isEnabled,
    );
  }
}
