<mat-sidenav #sideDrawer mode="over" position="end" class="p-1">
  <div class="grid-container">
    <div class="grid-title pl-2">
      <h2>{{ 'inhouse.settingsTabLabel' | translate }}</h2>
      <button
        mat-icon-button
        style="float: right"
        (click)="closeSideDrawer()"
        style="font-size: 24px"
      >
        <i class="fa-solid fa-x closeIcon"></i>
      </button>
    </div>
    <div
      *ngIf="enabledDeliveryStatus || enabledPickupStatus"
      class="grid-item"
      class="pt-3 pl-2"
    >
      <h2>{{ 'OrderDashboard.PauseNewOrders.Label' | translate }}</h2>
    </div>
    <div *ngIf="enabledDeliveryStatus" class="grid-item pl-2">
      <h3>
        {{ 'OrderDashboard.PauseDeliveryOrders.Label' | translate }}
        <i
          class="fa-regular fa-circle-info ml-3 tooltip"
          [matTooltip]="
            'OrderDashboard.PauseTooltip'
              | translate: { module: ('global.deliveryTitle' | translate) }
          "
        ></i>
      </h3>
      <mat-slide-toggle
        color="primary"
        class="module__toggle pr-4"
        [(ngModel)]="pauseDeliveryStatus"
        (change)="onPauseStatusChangeDelivery($event)"
      ></mat-slide-toggle>
    </div>
    <div *ngIf="enabledPickupStatus" class="grid-item pl-2">
      <h3>
        {{ 'OrderDashboard.PausePickupOrders.Label' | translate }}
        <i
          class="fa-regular fa-circle-info ml-3 tooltip"
          [matTooltip]="
            'OrderDashboard.PauseTooltip'
              | translate
                : { module: ('global.takeAwayAndPickupTitle' | translate) }
          "
        ></i>
      </h3>
      <mat-slide-toggle
        color="primary"
        class="module__toggle pr-4"
        [(ngModel)]="pausePickupStatus"
        (change)="onPauseStatusChangePickup($event)"
      ></mat-slide-toggle>
    </div>
    <div class="grid-item" class="pt-3 pl-2">
      <h2>{{ 'ordersDashboard.layoutLabel' | translate }}</h2>
    </div>
    <div class="grid-item pl-2">
      <h3>{{ 'ordersDashboard.extraTitleLabel' | translate }}</h3>
      <mat-slide-toggle
        color="primary"
        class="module__toggle pr-4"
        [(ngModel)]="extraSettingsValue"
        (change)="onStatusChangeDisplayExtraTitles($event)"
      ></mat-slide-toggle>
    </div>
    <div class="grid-item pl-2">
      <h3>
        {{ 'ordersdashboard.groupOrdersForReceipt' | translate }}
        <i
          class="fa-regular fa-circle-info ml-3 tooltip"
          [matTooltip]="'ordersdashboard.groupingTooltip' | translate"
        ></i>
      </h3>

      <mat-slide-toggle
        color="primary"
        class="module__toggle pr-4"
        [(ngModel)]="groupOrderPrinting"
        (change)="onStatusChangeGroupOrderPrinting($event)"
      ></mat-slide-toggle>
    </div>

    <div class="grid-item pl-2">
      <h2>{{ 'ordersDashboard.Filters' | translate }}</h2>
    </div>
    <div class="grid-item pl-2 mb-0">
      <h3>{{ 'orderDashboard.FilterByArea' | translate }}</h3>
    </div>

    <mat-form-field class="pl-1">
      <mat-label *ngIf="selectedAreas.length === 0"
        ><h3>{{ 'ordersdashboard.allAreasLabel' | translate }}</h3></mat-label
      >
      <mat-select
        #areaSelect
        (selectionChange)="onQrCodeAreaChange($event)"
        [(ngModel)]="selectedAreas"
        multiple
      >
        <mat-option *ngFor="let area of qrCodeAreas" [value]="area">
          {{ area.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="grid-item pl-2 mb-0">
      <h3>{{ 'orderDashboard.FilterByMenu' | translate }}</h3>
    </div>

    <mat-form-field class="pl-1">
      <mat-label *ngIf="selectedMenus.length === 0"
        ><h3>{{ 'ordersDashboard.allMenusLabel' | translate }}</h3></mat-label
      >
      <mat-select
        #menuSelect
        (selectionChange)="onMenuChange($event)"
        [(ngModel)]="selectedMenus"
        multiple
      >
        <mat-option *ngFor="let menu of activeMenus" [value]="menu">
          {{ menu.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-sidenav>
