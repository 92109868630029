import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { BusinessConfigurationService } from '../services/business-configuration.service';
import BusinessFacade from '../../../../../../apps/orderadmin/src/app/modules/business/business.facade';
import { SnackbarService } from '../services/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { BusinessConfiguration } from '@adminpanels/api-clients/orderadmin-api-client';

@Injectable({
  providedIn: 'root',
})
export class BusinessConfigurationState implements OnDestroy {
  private readonly _businessConfiguration =
    new BehaviorSubject<BusinessConfiguration | null>(null);
  public readonly businessConfiguration$ =
    this._businessConfiguration.asObservable();

  private readonly _saveBusinessConfiguration =
    new BehaviorSubject<BusinessConfiguration | null>(null);
  private readonly _destroy$ = new Subject<void>();

  configurationSaved = '';

  constructor(
    private readonly businessConfigurationService: BusinessConfigurationService,
    private readonly businessFacade: BusinessFacade,
    private readonly snackbarservice: SnackbarService,
    private readonly _translateService: TranslateService,
  ) {
    this.businessFacade.businessId$.data$
      .pipe(takeUntil(this._destroy$))
      .subscribe((businessId) => {
        if (businessId) {
          this.fetchBusinessConfiguration(businessId);
        } else {
          // Log: No business ID found
        }
      });

    this._saveBusinessConfiguration
      .pipe(
        switchMap((config) => {
          if (config) {
            return this.businessConfigurationService.saveOrUpdateConfiguration(
              config,
            );
          } else {
            return of(null);
          }
        }),
        takeUntil(this._destroy$),
      )
      .subscribe({
        next: () => {
          // Log: Business configuration saved successfully
          if (this.configurationSaved) {
            let configurationFor, confugurationName, successfullySaved;
            this._translateService.get('BusinessConfigaration.ConfigurationFor').subscribe((text) => {configurationFor = text});
            this._translateService.get(this.configurationSaved).subscribe((text) => {confugurationName = text});
            this._translateService.get('BusinessConfigaration.SuccessfullySaved').subscribe((text) => {successfullySaved = text});
            this.snackbarservice.trigger(
              `${configurationFor} ${confugurationName} ${successfullySaved}`,
            );
            this.configurationSaved = '';
          }
        },
        error: (err) => {
          // Log: Failed to save business configuration
        },
      });
  }

  public setBusinessConfiguration(
    businessConfiguration: BusinessConfiguration,
    configurationSaved?: string,
  ): void {
    if (typeof configurationSaved !== 'undefined') {
      this.configurationSaved = configurationSaved;
    }
    this._businessConfiguration.next(businessConfiguration);
    this._saveBusinessConfiguration.next(businessConfiguration);
  }

  private fetchBusinessConfiguration(businessId: number): void {
    this.businessConfigurationService
      .getConfigurationByBusinessId(businessId)
      .pipe(
        tap((config) => this._businessConfiguration.next(config)),
        takeUntil(this._destroy$),
      )
      .subscribe({
        next: (config) => {
          // Log: Business configuration fetched successfully
        },
        error: (err) => {
          // Log: Failed to fetch business configuration
        },
      });
  }

  public getBusinessConfiguration(
    businessId: number,
  ): Observable<BusinessConfiguration> {
    const currentConfig = this._businessConfiguration.getValue();
    if (currentConfig && currentConfig.businessId === businessId) {
      return of(currentConfig);
    } else {
      return this.businessConfigurationService
        .getConfigurationByBusinessId(businessId)
        .pipe(tap((config) => this._businessConfiguration.next(config)));
    }
  }

  public refreshBusinessConfiguration(businessId: number | null): void {
    if (businessId !== null) {
      this.fetchBusinessConfiguration(businessId);
    } else {
      // Log: Cannot refresh configuration: businessId is null
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
