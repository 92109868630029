import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import BusinessFacade from '../../../../../../apps/orderadmin/src/app/modules/business/business.facade';
import { BusinessConfigurationState } from '../../core/states/business-configuration.state';
import ModuleService from '../../../../../../apps/orderadmin/src/app/modules/main/pages/modules/module.service';
import { serviceTypesData } from '../../../../../../apps/orderadmin/src/app/constants/service-type-data';
import { SidebarSettingsService } from '../../core/services/side-bar-settings.service';
import { MatSelect } from '@angular/material/select';
import { MenusService } from '../../../../../../apps/orderadmin/src/app/modules/main/pages/menu/components/overview-menus/menus.service';
import {
  AreaResponse,
  GetAllAreasResponse,
  MenuDto,
} from '@adminpanels/api-clients/orderadmin-api-client';

import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@adminpanels/shared-ui';
import { BusinessConfigurationService } from '../../core/services/business-configuration.service';
import { MODULE_STATUS } from '../../core/constants';
import { OrderModule } from '../../../../../../apps/orderadmin/src/app/modules/main/pages/modules/module.enum';

@Component({
  selector: 'sidebar-settings',
  templateUrl: './sidebar-settings.component.html',
  styleUrls: ['./sidebar-settings.component.scss'],
})
export class SidebarSettingsComponent implements OnInit {
  @ViewChild('sideDrawer', { static: true }) public sideDrawer!: MatSidenav;
  @ViewChild('areaSelect') select!: MatSelect;
  @ViewChild('menuSelect') menuSelect!: MatSelect;
  constructor(
    private readonly _businessFacade: BusinessFacade,
    private _businessConfigurationState: BusinessConfigurationState,
    private readonly _moduleService: ModuleService,
    private readonly _sidebarService: SidebarSettingsService,
    public readonly _menuService: MenusService,
    private readonly _translateService: TranslateService,
    private readonly _snackbarService: SnackbarService,
    private readonly _businessConfigurationService: BusinessConfigurationService,
  ) {}

  extraSettingsValue = false;
  groupOrderPrinting = false;
  pauseDeliveryStatus = false;
  pausePickupStatus = false;
  enabledDeliveryStatus = false;
  enabledPickupStatus = false;

  businessId: number | null = null;

  qrCodeAreas: AreaResponse[] = [];
  activeMenus: MenuDto[] = [];
  selectedAreas: AreaResponse[] = [];
  selectedMenus: MenuDto[] = [];
  ngOnInit(): void {
    this.businessId = this._businessFacade.getCurrentBusinessId() || 0;
    this._businessConfigurationState
      .getBusinessConfiguration(this.businessId)
      .subscribe((config) => {
        if (config?.dashboardConfiguration?.displayExtraTitles) {
          this.extraSettingsValue =
            config.dashboardConfiguration.displayExtraTitles || false;
        }
        if (config.dashboardConfiguration?.groupOrderPrinting) {
          this.groupOrderPrinting =
            config.dashboardConfiguration.groupOrderPrinting || false;
        }
      });
    this.getAllQrCodeArea();
    this.getAllActiveMenus();
    this.refreshModuleStates(this.businessId);
  }

  public toggle() {
    this.sideDrawer.toggle();
  }
  closeSideDrawer() {
    this.sideDrawer.close();
  }

  onStatusChangeDisplayExtraTitles(value: MatSlideToggleChange) {
    if (this.businessId !== null) {
      this._businessConfigurationState
        .getBusinessConfiguration(this.businessId)
        .subscribe((config) => {
          if (config.dashboardConfiguration) {
            config.dashboardConfiguration.displayExtraTitles = value.checked;
            this._businessConfigurationState.setBusinessConfiguration(config);
          }
        });
    }
  }

  onStatusChangeGroupOrderPrinting(value: MatSlideToggleChange) {
    if (this.businessId !== null) {
      this._businessConfigurationState
        .getBusinessConfiguration(this.businessId)
        .subscribe((config) => {
          if (config.dashboardConfiguration) {
            config.dashboardConfiguration.groupOrderPrinting = value.checked;
            this._businessConfigurationState.setBusinessConfiguration(config);
          }

          const statusKey = value.checked ? 'activated' : 'deactivated';

          this._translateService
            .get([
              'ordersdashboard.groupingConfirmation',
              `global.status.${statusKey}`,
            ])
            .subscribe((translations) => {
              const message = `${translations['ordersdashboard.groupingConfirmation']} ${translations[`global.status.${statusKey}`]}.`;
              this._snackbarService.trigger(message);
            });
        });
    }
  }

  getAllQrCodeArea() {
    this._businessFacade.businessId$.data$.subscribe((businessId) => {
      this._moduleService
        .fetchAreas(businessId as number, serviceTypesData.Inhouse.name)
        .subscribe((response: GetAllAreasResponse) => {
          this.qrCodeAreas = response.areas ?? [];
          this.handleSelectedQrCodeArea();
        });
    });
  }

  getAllActiveMenus() {
    this._businessFacade.businessId$.data$.subscribe((businessId) => {
      this._menuService
        .getActiveMenus(businessId as number)
        .subscribe((menus) => {
          this.activeMenus = menus ?? [];
          this.handleSelectedMenu();
        });
    });
  }

  onQrCodeAreaChange($event: any) {
    this._sidebarService.setAreaData($event.value);
  }

  onMenuChange($event: any) {
    this._sidebarService.setMenusData($event.value);
  }

  handleSelectedQrCodeArea() {
    this._sidebarService.$dataArea.subscribe((data) => {
      this.selectedAreas = [];
      const selectedQrCodes = this.qrCodeAreas.filter((area) =>
        data.some((a) => area.areaId === a.areaId),
      );
      this.selectedAreas.push(...selectedQrCodes);
    });
  }

  handleSelectedMenu() {
    this._sidebarService.$dataMenu.subscribe((data) => {
      this.selectedMenus = [];
      const selectedMenus = this.activeMenus.filter((menu) =>
        data.some((a) => menu.menuId === a.menuId),
      );
      this.selectedMenus.push(...selectedMenus);
    });
  }
  onPauseStatusChangeDelivery(event: MatSlideToggleChange) {
    this._businessFacade.businessId$.data$.subscribe((businessId) => {
      this._businessConfigurationService
        .updateDeliveryPauseState(businessId as number, event.checked)
        .subscribe(() => {
          this.refreshModuleStates(businessId as number);
        });
    });
  }

  onPauseStatusChangePickup(event: MatSlideToggleChange) {
    this._businessFacade.businessId$.data$.subscribe((businessId) => {
      this._businessConfigurationService
        .updatePickupPauseDelivery(businessId as number, event.checked)
        .subscribe(() => {
          this.refreshModuleStates(businessId as number);
        });
    });
  }

  refreshModuleStates(businessId: number) {
    this._businessConfigurationService
      .fetchModules(businessId)
      .subscribe((response) => {
        const modulesState = response.filter(
          (item: any) =>
            item.name === OrderModule.TakeAway ||
            item.name === OrderModule.Delivery,
        );
        modulesState.forEach((item: any) => {
          if (item.name === OrderModule.TakeAway) {
            this.enabledPickupStatus = item.isEnabled;
            this.pausePickupStatus = item.isPaused;
          }

          if (item.name === OrderModule.Delivery) {
            this.enabledDeliveryStatus = item.isEnabled;
            this.pauseDeliveryStatus = item.isPaused;
          }
        });
        sessionStorage.setItem(MODULE_STATUS, JSON.stringify(modulesState));
      });
  }
}
