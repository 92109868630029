import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, pipe, throwError } from 'rxjs';
import { environment } from '../../../../../../apps/orderadmin/src/environments/environment';
import {
  BusinessConfiguration,
  BusinessConfigurationClient,
  BusinessServiceTypeResponseModel,
  BusinessServiceTypesClient,
  ServiceTypeStatusUpdateDto,
} from '@adminpanels/api-clients/orderadmin-api-client';
import { Module } from '../../../../../../apps/orderadmin/src/app/modules/main/pages/modules/models/module.model';
import { MODULE_STATUS } from '../constants';
import { OrderModule } from '../../../../../../apps/orderadmin/src/app/modules/main/pages/modules/module.enum';

@Injectable({
  providedIn: 'root',
})
export class BusinessConfigurationService {
  private readonly apiUrl = `${environment.API_URL}/BusinessConfiguration`;

  constructor(
    private readonly http: HttpClient,
    private readonly _businessConfigurationClient: BusinessConfigurationClient,
    private readonly _businessServiceTypesClient: BusinessServiceTypesClient,
  ) {}

  public getConfigurationByBusinessId(
    businessId: number,
  ): Observable<BusinessConfiguration> {
    return this._businessConfigurationClient.getConfigurationByBusinessId(
      businessId,
    );
  }

  public saveOrUpdateConfiguration(
    businessConfiguration: BusinessConfiguration,
  ): Observable<void> {
    return this._businessConfigurationClient.saveOrUpdateConfiguration(
      businessConfiguration,
    );
  }

  public updateDeliveryPauseState(
    businessId: number,
    status: boolean,
  ): Observable<void> {
    const modules = sessionStorage.getItem(MODULE_STATUS);
    return this.updateStatusModule(
      modules,
      OrderModule.Delivery,
      status,
      businessId,
    );
  }

  public updatePickupPauseDelivery(
    businessId: number,
    status: boolean,
  ): Observable<void> {
    const modules = sessionStorage.getItem(MODULE_STATUS);
    return this.updateStatusModule(
      modules,
      OrderModule.TakeAway,
      status,
      businessId,
    );
  }
  public fetchModules(
    businessId: number,
  ): Observable<BusinessServiceTypeResponseModel[]> {
    return this._businessServiceTypesClient
      .get(businessId)
      .pipe(
        map((response: BusinessServiceTypeResponseModel[]) =>
          response.map(
            (item: BusinessServiceTypeResponseModel) => new Module(item),
          ),
        ),
      );
  }

  updateStatusModule(
    modules: any,
    moduleName: string,
    status: boolean,
    businessId: number,
  ) {
    if (modules) {
      const modulesArray = JSON.parse(modules);
      const module = modulesArray.find((x: Module) => x.name === moduleName);
      if (module) {
        const moduleId = module.serviceTypeId;
        const payload = new ServiceTypeStatusUpdateDto({
          serviceTypeId: moduleId,
          pauseStatus: status,
        });
        return this._businessServiceTypesClient.updateServiceTypePauseStastus(
          payload,
          businessId,
        );
      } else {
        return throwError(
          () => new Error(`Module with name ${moduleName} not found.`),
        );
      }
    } else {
      return throwError(
        () => new Error(`Module with name ${moduleName} not found.`),
      );
    }
  }
}
