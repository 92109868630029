import { Component, Inject, Input, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: 'orderadmin-frontend-elfsight-banner-widget',
  templateUrl: './elfsight-banner-widget.component.html',
})
export class ElfsightBannerWidgetComponent implements OnInit, OnDestroy {
  @Input() isProduction = false;
  private scriptElement?: HTMLScriptElement;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    if (this.isProduction) {
      this.scriptElement = this.renderer.createElement('script') as HTMLScriptElement;
      this.scriptElement.src = 'https://static.elfsight.com/platform/platform.js';
      this.scriptElement.async = true;

      this.scriptElement.onerror = () => {
        console.error('Failed to load Elfsight platform script.');
      };

      this.renderer.appendChild(this.document.head, this.scriptElement);
    }
  }

  ngOnDestroy(): void {
    if (this.scriptElement) {
      this.renderer.removeChild(this.document.head, this.scriptElement);
      this.scriptElement = undefined;
    }
  }
}
